/* General Gallery Container */
.custom-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

/* Gallery Item */
.gallery-item {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition:
    transform 0.3s,
    box-shadow 0.3s;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

/* Hover Effect for Gallery Items */
.gallery-item:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Ligh Gallery Package CSS */
.lg-content {
  top: 72px !important;
}

.lg-toolbar .lg-close {
  position: absolute;
  top: 80px;
  right: 15px;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  z-index: 999;
}

.lg-toolbar .lg-close:hover {
  color: #ff4c4c;
}

/* Responsive Design */
@media (max-width: 768px) {
  .gallery-item img {
    width: 100%;
    height: auto; /* Allow dynamic height for smaller screens */
  }
}

@media (max-width: 480px) {
  .gallery-item {
    margin: 5px;
  }
}

.thumbnail-container {
  position: relative;
  width: 100%;
  height: 150px;
  overflow: hidden;
}

.thumbnail-grid-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.thumbnail-grid {
  display: flex;
  height: 100%;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  gap: 20px;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
  white-space: nowrap;
}

.thumbnail-grid::-webkit-scrollbar {
  display: none;
}

.thumbnail {
  flex: 0 0 calc(25% - 20px);
  height: 98%;
  width: calc(25% - 20px);
  object-fit: cover;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

.thumbnail:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.thumbnail-container .scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  font-size: 24px;
  z-index: 1;
  width: 60px;
  height: 60px;
}

.thumbnail-container .scroll-button.left {
  left: 10px;
}

.thumbnail-container .scroll-button.right {
  right: 10px;
}

.thumbnail-container .scroll-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Responsive Design */
@media screen and (max-width: 1024px) {
  .thumbnail {
    flex: 0 0 calc(33.33% - 10px);
  }
}

@media screen and (max-width: 768px) {
  .thumbnail {
    flex: 0 0 calc(50% - 10px);
  }
}

@media screen and (max-width: 480px) {
  .thumbnail {
    flex: 0 0 calc(100% - 10px);
  }
}
