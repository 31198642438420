h3 span {
  color: #3a3a3a;
  font-size: 24px;
}

.formobileicon {
  display: none;
}

.hostingbtnformobile {
  display: none;
}

.od-banner {
  /* background-image: url(../../detailspage/free-registration-back-img.png); */
  background: radial-gradient(91.5% 205.4% at 50% 49.02%,
      #222222 1.1%,
      #222222 29.18%,
      #424242 49.71%,
      #424242 76.04%);
  background-repeat: no-repeat;
  background-size: cover;
  margin: 100px 20px 20px 20px !important;
  background-origin: padding-box;
  border-radius: 20px;
  height: 500px;
}

.od-banner.no-banner {
  height: max-content;

  .resgistration-closed-padding {
    padding-bottom: 30px;
  }
}

.rupyee {
  width: 27px !important;
}

h3.od-content-tilte {
  margin-bottom: 0.2em;
}

.resgistration-closed-padding {
  padding-bottom: 80px;
}

.od-banner-img {
  width: -webkit-fill-available;
  -moz-box-shadow: 0px 0px 10px 0px #ccc;
  -webkit-box-shadow: 0px 0px 10px 0px #ccc;
  box-shadow: 0px 0px 20px 0px #ccc;
  width: 100%;
}

.list-icon-img {
  width: 100%;
}

.od-card {
  border-radius: 10px;
  box-shadow: 0px 4px 30px #00000012;
  margin-left: auto;
  margin-right: auto;
}

.od-body {
  padding-top: 80px;
  padding-bottom: 80px;
}

.od-card-leftside {
  height: 100%;
  padding: 20px;
  border-radius: 10px 0px 0px 10px;
  text-align: center;
  /* background-image:  linear-gradient( to bottom, transparent, #a357e0 ),url(../../detailspage/free-registration-bg-img.jpg); */
  background-repeat: round;
}

.left-box {
  height: 100%;
  padding: 20px;
  border-radius: 10px 0px 0px 10px;
  text-align: center;
  background-image: linear-gradient(to bottom, transparent, #a357e0),
    url(../../detailspage/free-registration-bg-img.jpg);
  background-repeat: round;
}

.leftside-register button:hover {
  background-color: #8d6dec !important;
  border-color: #8d6dec !important;
}

.od-leftside-heading {
  /* padding-top: 35px; */
}

.od-leftside-heading h5 {
  color: white;
  font-size: 25px;
  font-weight: 600;
}

.stat-card {
  text-align: center;
  align-items: center;
  border: 1px dashed white;
  margin: 10px;
  margin-left: 30px;
  margin-right: 20px;
  padding: 20px;
  height: 120px;
  width: 60px;
  border-radius: 8px;
}

.stat-card:last-of-type {
  text-align: center;
  align-items: center;
  border: 1px dashed white;
  margin: 10px 10px 10px 0px;
  margin-left: 0px;
  margin-right: 30px;
  padding: 20px;
  height: 120px;
  width: 60px;
  border-radius: 8px;
}

.stat-card svg {
  color: white;
  margin-right: 0px;
  margin-bottom: 10px;
  font-size: 30px;
}

.stat-card p {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #fff !important;
  margin: 0px;
  line-height: 1;
}

.stat-card p:first-of-type {
  font-size: 25px;
  font-weight: 600;
  color: transparent;
  margin: 0px;
  margin-bottom: 6px;
  line-height: 1;
  /*  -webkit-text-fill-color: none;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white; */
}

.leftside-icon-bt {
  margin: 35px 10px 10px;
  text-align: center;
}

.leftside-icon-bt Button {
  background-color: #8d6dec;
  text-align: center;
  border: none;
  padding: 5px 10px;
  margin-right: 10px;
}

.leftside-icon-bt Button:last-of-type {
  background-color: #8d6dec;
  text-align: center;
  border: none;
  padding: 5px 10px;
  margin-left: 10px;
  margin-right: 0px;
}

.leftside-icon-bt Button svg {
  margin: 0px;
  font-size: 15px;
}

.leftside-register {
  margin-top: 10px;
  padding: 10px;
}

.leftside-register Button {
  color: #a357e0;
  background-color: #ffffff;
  border: none;
  box-shadow: 5px 10px #7f4af5;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 600;
  align-items: center;
}

.leftside-register Button svg {
  font-size: 14px;
  margin-right: 5px;
}

.right {
  padding: 0px;
}

.od-card-rightside {
  height: 100%;
  padding: 20px;
  border-radius: 10px 10px 10px 10px;
  background-color: white;
  padding-bottom: 0px;
}

.od-rightside-heading {
  padding: 10px;
}

.od-rightside-heading h5 {
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}

.rightside-sub-heading {
  color: #a5a5a5;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
}

.details-list {
  padding-left: 10px;
}

.details-list li {
  display: flex;
}

.list-icon svg {
  margin-right: 0px;
}

.list-content {
  margin-left: 10px;
  width: 100%;
}

.list-content-title {
  border-bottom: 1px dashed #a5a5a5;
  width: 100%;
  margin-bottom: 0px;
  font-weight: 700;
  font-size: 15px;
}

.list-content-text {
  color: #717171 !important;
  font-size: 16px;
}

.list-content p:first-child {
  margin-bottom: 5px !important;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.eligibility-area {
  padding-top: 10px;
}

.eligibility-area Button {
  background-color: #a357e0;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 30px;
  border: none;
  margin-right: 10px;
}

.od-nav {
  padding: 8px 60px 0px 60px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.od-nav-links {
  display: inline-flex;
  /* justify-content: space-around; */
  text-align: center;
  /* width: 90%; */
  padding-left: 0px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 1px 30px #0000001f;
  padding: 5px;
  border-radius: 50px;
  margin-bottom: 10px;
  border: 1px solid #191f23;
}

.od-nav-links li {
  /* border-right: 0.5px solid #dadada; */
  padding: 10px 25px 10px 25px;
}

/* .od-nav-links li:last-of-type {
    border-right: none;
    padding: 5px 25px 5px 25px;
} */

.od-nav-links li a {
  text-decoration: none;
  color: #000;
  font-size: 14px;
  font-weight: 400;
}

.od-nav-links li a svg {
  margin-right: 10px;
  font-size: 20px;
  margin-bottom: 5px;
}

/* collapsing tabs */

.od-rounds {
  background-image: url(../../detailspage/reqard-and-prize-bg-img.jpg);
  padding: 40px 100px;
}

.od-rounds-heading h3 {
  font-size: 25px;
  font-weight: 700;
}

@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css");

.panel-title>a:before {
  float: right !important;
  font-family: FontAwesome;
  content: "\f068";
  padding-right: 5px;
}

.panel-title>a.collapsed:before {
  float: right !important;
  content: "\f067";
}

.panel-title>a:hover,
.panel-title>a:active,
.panel-title>a:focus {
  text-decoration: none;
}

.od-content {
  padding: 0px 100px;
}

.od-content:target {
  padding-top: 100px;
}

.content-body {
  padding: 33px 39px 20px 27px;
  margin: 50px 0px;
  /* box-shadow: 0px 2px 15px #00000026; */
}

.od-content-text {
  color: #191f23 !important;
  font-weight: 400;
  line-height: 1.5;
  font-size: 16px;
  text-align: justify;
  margin-bottom: 0;
  margin-top: 10px;
}

.rounddate {
  margin-top: 18px;
  margin-bottom: 10px;
}

.od-content-text span {
  margin-top: 10px;
  margin-top: 10px;
  background: none !important;
  /* color: #fff !important; */
}

.aboutcompny p {
  text-align: center !important;
  color: #fff !important;
  padding: 15px;
  margin-bottom: -5px;
}

.od-content-text svg {
  color: black;
}

#aboutcompny .container h2 {
  color: #fff;
}

#aboutcompny .container h2 span {
  color: #000;
}

#aboutcompny .container {
  padding: 10px;
  text-align: justify;
  text-align: center;
  padding: 60px 0px 25px;
  padding: 20px 0 15px;
  /* background: url(/static/media/about_img.d115611f.png); */
  /* background-size: contain; */
  background-repeat: no-repeat;
  /* background-position: 93% 5px; */
  background: #707070;
  border-radius: 40px;
  /* background-position: 93% 5px; */
  /* margin-top: -80px; */
}

.dnd {
  /* background-image: url(../../detailspage/dates-and-deadline-bg-img.jpg); */
  text-align: center;
  padding: 60px 0px 25px;
  background: #f5f5fa;
}

.dnd-heading h3 {
  font-size: 16px;
  font-weight: 700;
  color: #191f23;
  margin-bottom: 0;
  /* border-bottom: 1px solid; */
  text-align: left;
}

.dnd-heading h3::after {
  background: #000 none repeat scroll 0 0;
  content: "";
  display: block;
  height: 1px;
  margin-top: 13px;
  width: 24px;
}

.dnd-heading span {
  color: #191f23;
  font-size: 16px;
  font-weight: 400;
}

.dnd Row {
  padding: 10px;
}

.dnd-body {
  display: flex;
  background-color: #fff;
  border-radius: 5px;
  padding: 25px 0px 12px 12px;
  /* margin-top: 20px; */
}

.dnd-body img {
  padding-right: 15px;
  /* width: 70%; */
  margin-bottom: 12px;
}

.dnd-timeslot {
  margin-bottom: 0;
  /* font-weight: 700; */
  font-size: 16px;
  /* color: #a357e0 !important; */
  text-align: left;
  margin-top: 12px;
}

.dnd-timeslot span {
  font-weight: bold;
}

.dnd-timeslot-type {
  text-align: left;
  margin-bottom: 0px;
}

.download-attachment {
  text-align: center;
  padding: 60px 100px 55px;
}

.detail-download {
  margin-right: 14px;
  margin-top: 12px;
  /* margin-top: 10px; */
}

.detail-download svg {
  font-size: 15px;
  margin-top: -4px;
}

.detail-download svg {
  font-size: 15px;
  margin-top: -4px;
}

.fileUpload svg {
  font-size: 15px;
  /* margin-top: -4px; */
}

.da-heading h3 {
  font-size: 25px;
  font-weight: 700;
}

.da-body Row {
  padding: 20px;
}

.da-content {
  margin: 15px 0px;
}

.da-content a {
  padding: 10px 20px;
  border: 1px dashed #cacaca;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  display: inline-block;
}

.da-content a svg {
  color: red;
}

.rewards-prizes {
  /* background-image: url(../../detailspage/reqard-and-prize-bg-img.jpg); */
  text-align: center;
  padding: 0 0 40px;
}

.rp-content-body {
  padding: 20px 30px;
  border: 1px dashed #d4d4d4;
  margin: 20px 0px 20px 0px;
  text-align: left;
  /* box-shadow: 0px 2px 30px #00000014; */
}

.rp-content-text {
  color: #423f3f;
  font-weight: 400;
  line-height: 1.8;
  font-size: 16px;
}

/* .opportunity-detail-heading{font-size: 24px;} */

.rp-content-tilte {
  font-size: 24px;
}

.ra-card-content {
  padding: 15px;
}

.read-articles {
  text-align: center;
  padding: 60px 100px;
}

.ra-card-title {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
}

.star-container svg {
  width: 25px !important;
  height: 25px !important;
}

/* .star-container svg path{fill: #ff9f00 !important;} */

.complaint {
  background-image: url(../../detailspage/raise-a-complaint-bg-img.png);
  text-align: center;
  padding: 40px 100px;
}

.complaint-text p {
  color: white;
  font-size: 20px;
  font-weight: 600;
  line-height: 2;
}

.complaint-btn {
  background-color: #ff9900;
  border: none;
  border-radius: 50px;
  padding: 15px 50px;
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: -90px;
}

.complaint-btn img {
  margin-right: 10px;
}

.review {
  text-align: center;
  /* margin-top: 70px; */
  /* margin-bottom: 5% !important; */
  background-color: #f5f5f5;
  padding-bottom: 5% !important;
}

.review-heading h3 {
  font-size: 25px;
  font-weight: 700;
}

.review-bottom {
  display: flex;
  justify-content: space-between;
}

.rating span {
  font-size: 40px;
}

.review-input textarea {
  height: 220px;
  margin: 20px 0px;
  padding: 12px;
}

.review-input-area {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: none;
  background-color: #e9f0fa;
}

.review-input-area:focus-visible {
  border: 1px solid #9c48fb;
  outline: 0;
}

.review-send Button {
  padding: 5px 20px;
  border-radius: 30px;
  font-weight: 600;
}

.overall-rating {
  text-align: center;
  padding: 60px 0px 60px 0;
}

.overall-rating-heading h3 {
  font-size: 25px;
  font-weight: 700;
}

.feedback-list {
  width: 44%;
  margin: 50px auto;
  border-radius: 30px;
  -moz-box-shadow: 0px 0px 10px 0px #ccc;
  -webkit-box-shadow: 0px 0px 10px 0px #ccc;
  box-shadow: 0px 0px 10px 0px #ccc;
}

.feedback-list ul {
  display: flex;
  justify-content: space-around;
  padding: 10px 10px;
  margin-bottom: 0px;
}

.feedback-list ul li {
  border-right: 1px solid #dadada;
  padding: 0px 20px;
  font-size: 20px;
  font-weight: 500;
  color: #717171;
}

.feedback-list ul li:last-of-type {
  border-right: 0;
  padding: 0px 20px;
}

.feedback-bottom button {
  background: #5736ba;
  font-weight: 500;
}

.feedback-bottom button:hover {
  background: #7e5de0;
}

.comment-card {
  margin: 20px 0px;
  padding: 30px;
  text-align: left;
  border-bottom: 5px solid orange;
}

.comment-body {
  display: flex;
}

.person-image img {
  margin-right: 10px;
}

.person-name {
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 700;
}

.comment-details span {
  color: #a5a5a5;
  font-size: 15px;
  font-weight: 500;
}

.comment-details span svg {
  font-size: 15px;
  margin-right: 2px;
}

.review-comments {
  margin-top: 15px;
  margin-bottom: 0px;
  color: #a5a5a5;
  font-size: 17px;
  font-weight: 500;
}

.feedback-bottom {
  text-align: center;
  width: 50%;
  margin: 40px auto;
}

.feedback-bottom Button {
  padding: 10px 30px;
  border-radius: 30px;
  border: none;
  font-size: 20px;
  font-weight: 600;
}

.faqs {
  text-align: center;
  padding: 60px 100px;
  margin-top: 25px;
}

.faqs-heading h3 {
  font-size: 25px;
  font-weight: 700;
}

.faqs-bottom {
  text-align: center;
  width: 50%;
  margin: 40px auto;
}

.faqs-bottom Button {
  padding: 10px 30px;
  border-radius: 30px;
  border: none;
  font-size: 20px;
  font-weight: 600;
}

/* rating-circles */
.flex-wrapper {
  display: flex;
  flex-flow: row nowrap;
}

.single-chart {
  width: 33%;
  justify-content: space-around;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 50%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart.orange .circle {
  stroke: #ff9f00;
}

.circular-chart.green .circle {
  stroke: #4cc790;
}

.circular-chart.blue .circle {
  stroke: #3c9ee5;
}

.percentage {
  fill: #666;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
}

.nav-body {
  position: sticky;
  top: 70px;
  z-index: 2;
  background: #fff;
  /* box-shadow: 1px 1px 2px rgb(0 0 0 / 10%); */
  display: block;

  li {
    list-style: none;
  }
}

.opportunity-detail-body {
  background-color: #fff !important;
}

.nav ul .active a {
  color: blueviolet;
}

/* .active a{

      color:#F38A8A;
      border-bottom:3px solid #F38A8A;
   } */

/* logout day */
.popupnew-overlay .popupnew-content {
  width: 28% !important;
  border-radius: 5px;
}

.od-nav-links li a:hover {
  color: #7942f3 !important;
}

.opportunity-detail {
  margin-top: -13%;
}

.opportunity-detail.opportinity-banner .swiper {
  overflow: none !important;
}

@media screen and (min-width: 768px) {
  .opportinity-banner .past-workshops {
    margin-left: 0px !important;
  }
}

/* slider swiper */
.opportinity-banner .swiper .text-light {
  color: #000000 !important;
  display: none;
}

.opportinity-banner .past-workshops .swiper-pagination .swiper-pagination-bullet {
  width: 24px;
  border-radius: 0;
  height: 2px;
  background: #141313;
}

.rewardrank {
  font-size: 20px !important;
}

.prize-sec {
  background: #fff;
  justify-content: unset;
  height: 285px;
  box-shadow: 2px -4px 40px #00000014;
  border-radius: 20px;
  margin-bottom: 30px;
  padding: 15px 16px 8px 40px;
  /* height: 150px; */
}

.prize-sec img {
  margin-bottom: 20px;
}

.prize-sec p {
  margin-bottom: 0;
}

.prize-sec-price {
  position: relative;
  color: #5938ba !important;
  top: 8px;
}

.prize-sec i {
  color: rgba(25, 31, 35, 1);
  margin-right: 5px;
}

.awardicon {
  top: 11px;
  position: absolute;
  color: #fff;
  font-size: 38px;
  right: 22px;
}

.iconclor {
  color: #9b48fa;
  margin-right: 8px;
}

.certificate {
  font-size: 15px;
  color: #cc4c4c;
}

.fileUpload input[type="file"] {
  display: none;
}

.btn {
  /* background-color:#e3e3e3; */
  /* color:#333; */
  /* border:1px solid #e6e6e6; */
  border-radius: 3px;
  padding: 8px 12px;
  font-size: 14px;
}

.fileUpload {
  margin-top: 12px;
}

#round .content-body:hover {
  box-shadow: 0 2px 40px #00000038;
  background: #f5f5f5;
}

.content-body.card>span {
  position: absolute;
  right: 30px;
  top: -37%;
  font-size: 94px;
  font-weight: 700;
  letter-spacing: -3px;
  color: #6f6f6f;
  opacity: 0.2;
}

.dateandtime {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #191f23;
}

.roundcal {
  font-size: 17px;
  margin-right: 6px;
  margin-top: -3px;
}

.rounddate {
  margin-top: 18px;
}

.pricedec {
  font-size: 14.5px;
  line-height: 22px;
}

.prize_money {
  bottom: 14%;
  position: absolute;
}

.submission-detail {
  background: #fff;
}

.popup-content {
  padding: 30px !important;
  border-radius: 20px;
  text-align: justify;
  width: 60% !important;
}

@media screen and (max-width: 640px) {
  .popup-content {
    width: 90% !important;
  }
}

.fl {
  float: right;
}

.submitbtn {
  margin-top: 0;
}

.submitbtn:disabled {
  background-color: #d16d6d !important;
}

.submitbtn:hover {
  background-color: #b70202 !important;
}

.textupload {
  width: 140px;
  margin-left: 39% !important;
  margin-bottom: 43px !important;
}

.submissinmsg {
  text-align: center;
  color: green !important;
  margin-top: 12px;
}

.importantsec {
  justify-content: center;
}

.casestudy {
  padding: 0 15px;
  text-align: right;
  display: flex;
  justify-content: end;
}

.casestudy button {
  padding: 5px 15px 7px 15px;
  height: 39px;
}

.casestudy button a {
  margin-left: 6px;
}

div.flex {
  display: flex;
}

@media screen and (max-width: 480px) {
  .od-card {
    box-shadow: none !important;
  }

  .opportinity-banner {
    margin-top: 62px !important;
  }

  .od-nav-links {
    border-radius: 0;
  }

  .od-nav {
    padding: 0;
  }

  .od-nav-links li {
    padding: 0 8px;
  }

  .od-nav-links li a {
    font-size: 14px;
  }

  .stat-card,
  .stat-card:last-of-type {
    width: 46%;
    height: 90px;
    margin-left: 7px;
    margin-right: 5px;
    padding: 10px 0 0 0;
    border: 1px dashed #a357e0 !important;
  }

  .mobile-verion {
    margin-top: 15px !important;
  }

  .od-card-leftside {
    background-image: none !important;
    /* padding: 1px; */
  }

  .sub-round-box {
    display: none !important;
  }

  .opplogomobile {
    float: left;
    width: 38%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .img-title {
    display: flex;
    align-items: center;
    /* margin-bottom: 15px; */
  }

  .stat-card svg {
    font-size: 18px;
    color: #a357e0 !important;
  }

  .od-leftside-heading h5 {
    color: #000 !important;
    font-size: 15px;
    text-align: center;
    margin-top: 15px;
  }

  .od-leftside-heading {
    padding-top: 0px;
  }

  .stat-card p {
    font-size: 13px !important;
    color: #000 !important;
  }

  .scroll-tab {
    padding: 13px 8px 8px 8px;
  }

  .od-leftside-heading h5 {
    font-size: 22px;
  }

  .od-leftside-heading {
    padding-top: 14px;
  }

  .scroll-tab li:last-of-type {
    padding: 0 0 0 8px;
  }

  .eligibility-area Button {
    margin-bottom: 10px;
  }

  .rp-content-text p {
    text-align: justify !important;
  }

  .rp-content-text li {
    text-align: justify !important;
  }

  /* .roundCount {
        min-width: 40px !important;
        min-height: 40px !important;
        background: #770155 !important;
        border-radius: 50% !important;
        color: white !important;
        padding: 10px 5px 10px 10px !important;
        font-size: 13px !important;
        margin-right: 15px !important;
    } */
}

.mobile-verion .list-content-text {
  font-size: 15px;
  border-bottom: 1px dashed #a5a5a5;
}

.mobile-verion .list-content-text i {
  color: #6610f2;
  padding-right: 7px;
  border-bottom: 1px dashed #a5a5a5;
}

.mobile-verion .list-content-title {
  color: #000 !important;
}

.sub-round-box {
  display: flex;
  width: 100%;
  justify-content: space-around;
  border: 2px solid #a357e0;
  padding: 25px 0;
  border-radius: 10px;
  text-align: center;
}

.sub-round-box i,
.sub-round-box svg {
  font-size: 26px;
  color: #a357e0;
}

.sub-round-box-num {
  font-size: 18px;
  font-weight: 500;
  padding-top: 8px;
}

.sub-round-box-registeration-btn {
  text-align: center;
  margin-top: 20px;
}

.sub-round-box-registeration-btn button {
  background: #a357e0;
  border: 1px solid;
  padding: 8px 20px;
  border-radius: 5px;
  color: #fff;
}

.mobile-verion .submission-closed-section {
  padding-bottom: 25px;
}

.round-btn-wrapper {
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  /* .tab-to-desktop {
        display: none;
    } */

  h3 {
    font-size: 24px;
  }
}

@media screen and (min-width: 481px) and (max-width: 1023px) {
  .opportunity-detail .od-nav {
    padding: 0;
  }

  .scroll-tab li a {
    font-size: 14px;
  }

  .scroll-tab li,
  .scroll-tab li:last-of-type {
    padding: 6px 15px 2px 15px;
  }
}

@media screen and (min-width: 320px) and (max-width: 786px) {
  .stat-card:last-of-type {
    width: 40%;
  }

  .mobile-verion {
    display: none;
  }

  .od-nav-links {
    display: -webkit-inline-box;
    overflow: auto;
    width: 100%;
  }

  .resgistration-closed-padding {
    padding-bottom: 122px;
  }

  .resgistration-closed-padding .margin-40 {
    margin-bottom: 12px !important;
    margin-top: 12px;
  }

  h3.od-content-tilte {
    margin-bottom: 0.2em;
    display: inline-flex;
  }

  .desktoplogo {
    display: none;
  }

  .mobilelogo {
    display: block !important;
  }

  .opportinity-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
  }

  .stat-card {
    width: 40%;
  }

  .od-rightside-heading h5 {
    font-size: 13px;
  }

  .opportinity-title {
    font-family: "Inter" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 17px !important;
    line-height: 27px !important;
  }

  .display_flex {
    text-align: left;
    margin-top: 21px;
  }

  .display_flex .forth_row {
    font-weight: 700;
    font-size: 12px !important;
    text-align: center !important;
  }

  .mobileview .swiper {
    margin-top: 80px;
  }

  .display_flex span {
    color: #fff;
    /* display: inline-block !important; */
  }

  .fordesktop {
    display: none !important;
    opacity: 0;
  }

  .logo_image {
    width: 220px !important;
    height: 100px !important;
  }

  .buttnformobile {
    width: 100% !important;
  }

  .second_row_css {
    font-size: 12px !important;
    display: inline-block;
  }

  .od-banner {
    margin: 72px 0px 2px 0px !important;
    background-origin: padding-box;
    border-radius: 0 !important;
    height: auto;
  }

  .for-mobile {
    display: block !important;
  }

  .for-desktop {
    display: none !important;
  }

  .opportinity-banner {
    margin-top: -35% !important;
    position: absolute;
    width: 92%;
    margin-left: -3px;
    margin-bottom: 26px;
  }

  .scroll-tab {
    padding: 13px 10px 15px 8px;
  }

  .od-content-tilte_second {
    color: #191f23;
    font-size: 14px !important;
    font-weight: 600;
    position: relative;
    top: 2px;
  }


  .roundCount {
    min-width: 33px !important;
    max-height: 33px !important;
    background: #770155 !important;
    border-radius: 50% !important;
    color: white !important;
    /* padding: 10px 5px 10px 10px !important; */
    font-size: 11px !important;
    margin-right: 7px !important;
    padding-top: 2px;
  }

  .dateandtime {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 12px !important;
    line-height: 24px;
    color: #191f23;
  }

  .od-content-text {
    font-size: 13px !important;
  }

  .bigcount {
    display: none !important;
  }

  .round-title {
    text-align: center;
    margin-top: -11px !important;
    margin-bottom: -30px !important;
  }

  /* css for live button in mobile */
  .roundstatus {
    font-size: 10px !important;
    padding: 5px 0px 6px 11px !important;
    background: red;
    border-radius: 12px;
    color: #fff;
    height: 26px;
  }

  /* start  responsive mobile menu */
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    position: absolute !important;
    right: 0 !important;
    top: 98% !important;
    background: #0000005c;
    height: 100vh !important;
    width: 100% !important;
  }

  .logo_image img {
    width: 97% !important;
  }

  .buttnformobile button {
    width: 100%;
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding-left: 10px;
    flex-basis: 100%;
    flex-grow: 1;
    position: absolute !important;
    right: 0px !important;
    height: 100vh !important;
    text-align: left !important;
    width: 250px !important;
    margin-bottom: 0;
    list-style: none;
  }

  .navbar-nav .nav-link {
    height: 50px;
    text-align: left !important;
    margin-top: 10px !important;
  }

  .nav-item {
    text-align: left !important;
  }

  .nav-item {
    /* border-right: 1px solid #834bf5; */
    /* text-align: center !important; */
  }

  .formobileicon {
    display: block;
    margin-left: 100px;
    margin-top: 2px;
  }

  .header__sortname.formobileicon .usernamelatter {
    margin-top: 2px !important;
  }

  .hostingbtnformobile {
    display: block;
  }

  .fordesktopicon {
    display: none;
  }

  /* end hamburger menu */

  .od-nav-links li a.active {
    color: #fff !important;
  }

  .prize_money {
    bottom: 18%;
    position: absolute;
  }

  .prize-sec {
    background: #fff;
    justify-content: unset;
    height: 200px;
    box-shadow: 2px -4px 40px #00000014;
    border-radius: 20px;
    margin-bottom: 30px;
    display: inline-flex;
    padding: 15px 19px 6px 12px;
    width: 100%;
  }

  .rewardrank {
    font-size: 20px !important;
  }

  .prize-sec img {
    width: 55px;
    height: 55px;
    margin-right: 15px;
  }

  .review {
    margin-top: 10px;
  }

  .review .container {
    border-radius: 1px !important;
  }

  .hostedby {
    margin-top: -10px;
  }
}

.mobilelogo {
  display: none;
}

.round-timeline li {
  height: 26px !important;
}

@media screen and (max-width: 767px) {
  .mobile-verion .rightside-sub-heading {
    font-size: 17px;
  }

  .mobile-verion .details-list,
  .mobile-verion .od-card-rightside {
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .for-mobile {
    display: none !important;
  }

  .submission-closed-section {
    margin-left: 13%;
  }

  .opportinity-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
  }

  .mobile-verion {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .od-banner {
    padding-top: 0;
  }

  .od-card-leftside {
    border-radius: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .rp-card-body .col-md-4 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .stat-card {
    width: 44%;
  }

  .stat-card:last-of-type {
    width: 43%;
  }

  .prize-sec h4 {
    height: 57px;
  }

  .opportinity-banner {
    margin-top: -19.7% !important;
  }
}

/* @media screen and (max-width:1199px) {
   
} */

/* by hemant  */

.rp-content-text span p span {
  font-size: 16px !important;
  background-color: #fff0 !important;
}

.rp-content-text span ul li span {
  font-size: 16px !important;
}

.coupon-code {
  border: 1px #ccc solid;
  border-radius: 5px;
  padding-left: 10px;
  height: 38px;
  width: 86%;
}

.applybtn {
  background: linear-gradient(180deg, #ff283a -12.5%, #ff283a 131.82%), #ff283a;
  color: #fff !important;
}

.opportunity-detail .card {
  background-color: #f5f5fa !important;
  border-radius: 20px;
}

.content-body.roundSec.card {
  background-color: #fcf6f0 !important;
  border: none;
}

.roundCount {
  min-width: 30px;
  max-height: 30px;
  line-height: 30px;
  text-align: center;
  background: #770155;
  border-radius: 50%;
  color: white;
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;
}

.more-about .card {
  background-color: #fff;
  border-radius: 12px;
  border: none;
}

#contact {
  text-align: center;
  padding: 96px 0px 20px !important;
  background: #f5f5fa;
  padding: 150px 0 200px;
  background: url(../../detailspage/contact_bg.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% 25px;
  margin-top: -80px;

  /* border-bottom: 2px solid #e4e1e1; */
}

.contact-section {
  box-sizing: border-box;
  background: #ffffff;
  margin-right: 30px;
  /* border: 2px solid #1E1E1E; */
  border-radius: 15px;
  /* box-shadow: 3px 6px 10px #00000014; */
  /* box-shadow: -6px 12px #0bccfe, -6px 12px 0px 1px black; */
}

.eligible_time {
  margin-top: 7px;
}

.eligible_time p {
  margin-top: 5px;
}

.eligible_time p a {
  font-size: 14px;
  font-weight: 800;
  padding: 8px;
}

.eligible_class {
  display: flex;
  margin-left: 35px;
}

.eligible_class .buttonMain {
  background: #ff283a;
  align-items: center;
  padding: 8px 18px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  font-weight: 800;
  font-size: 14px;
  border-radius: 4px;
  text-decoration: none;
}

.eligible_class p:first-child {
  margin-top: -2px;
  margin-right: 15px;
  color: #fff !important;
  font-size: 14px;
  font-weight: 800;
  padding: 6px;
  text-transform: capitalize;
}

.quizdone {
  float: right;
  font-size: 15px;
  margin-top: 15px;
  margin-right: 0;
  height: 38px;
}

.playbtn {
  margin: 0px !important;
  padding: 0px !important;
}

.learnmore {
  cursor: pointer;
  color: #2570fa !important;
  font-size: 14px;
  font-weight: 600;
  position: relative;
}

.submissionbuttoncase {
  background-color: #ff283a !important;

  color: #fff !important;
  margin-top: 12px;
  margin-bottom: 34px;
  margin-right: 12px;
}

.submissionbuttoncase a {
  color: #fff !important;
}

.caseandsubmission {
  /* display: -webkit-box; */
  position: relative;
  justify-content: space-around;
}

.aboutcompny li {
  list-style: revert;
}

.prevsubmissionsubmit {
  display: flex;
  justify-content: space-between;
}

.opportinity-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 68px;
  color: #ffffff;
}

.display_flex {
  display: flex;
  margin-bottom: 20px;
  /* justify-content: space-between; */
}

.logo_image {
  /* width: 100px;
    margin-right: 15px;
    background: #fff;
    padding: 10%;
    margin-top: 10px;
    border-radius: 8px; */
  width: 130px;
  margin-right: 15px;
  background: #fff;
  height: 130px;
  border-radius: 8px;
  position: relative;
}

.logo_image img {
  width: 100%;
  padding: 12px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.display_flex span {
  color: #fff;
}

.second_row_css {
  font-size: 16px;
  padding-right: 21px;
  margin-bottom: -12px;
}

.reg_bttn svg {
  font-size: 15px;
}

.display_flex .forth_row span img {
  margin-right: 15px;
  width: 25px;
}

.display_flex .forth_row {
  color: #000;
  padding: 5px 15px 5px 15px;
  font-weight: 700;
  font-size: 15px;
  background-color: #fff;
  border-radius: 8px;
  /* color: #000; */
}

.forthrowSpan {
  font-weight: 500;
}

.calender_bttn button svg {
  font-size: 14px;
  color: red;
  margin-right: 8px;
  margin-top: -1px;
}

.display_flex .forth_row span {
  color: #000;
}

.reg_bttn {
  background: #ff283a;
  border-radius: 8px;
  font-weight: bolder;
  border: none;
}

.calender_bttn,
.calender_bttn.btn-primary:hover {
  background: none;
  border-radius: 8px;
  color: #ff283a;
  border: 1px solid #ff283a;
  margin-left: 15px;
  padding: 10px;
  /* margin-top: 4px; */
  height: 42px;
}

.reg_bttn {
  margin-right: 14px;
  padding: 10px 28px 10px 28px;
  margin-top: 4px;
}

.selectedtab {
  background-color: #ff283a;
  border-radius: 28px;
  padding: 20px 25px 15px 25px;
}

.selectedtab a {
  color: #fff !important;
}

.od-nav-links li a:hover {
  color: #000 !important;
}

/* .od-nav-links li a:hover
{
    color: #fff !important;
}
.od-nav-links li a:hover{
    background-color: #FF283A;
    border-radius: 28px;
    padding: 20px 25px 15px 25px;
} */
.section-title {
  text-align: center;
  margin-top: 15px;
}

.section-title.video-section-title {
  font-size: 1.6rem;
}

.video-section .rp-content-body,
.opportunity-gallery-section .rp-content-body {
  border: none;
}

.opportunity-gallery-section {

  /* Styles for the big image overlay */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .gallery-container {
    width: 60%;
    max-width: 800px;
    background: white;
    padding: 10px;
    border-radius: 10px;
    position: relative;
  }
}

@media screen and (min-width: 768px) {
  .past-workshops {
    margin-left: 0spx !important;
  }
}

.resgistration-closed-padding .margin-40 {
  margin-bottom: 20px;
}

.opportunity-detail.opportinity-banner {
  margin-top: -14.7%;
}

.form-body .input-titlle {
  font-weight: 600 !important;
}

.form-check .form-check-label {
  font-weight: 400;
}

.plantable .modal-sm {
  max-width: 900px;
}

.bold-800 {
  font-weight: 800;
}

.od-content-tilte_second {
  color: #191f23;
  font-size: 20px;
  font-weight: 600;
  position: relative;
  top: 2px;
}

.topsvgicon {
  font-size: 14px;
  display: inline-block;
  margin-right: 7px;
  margin-top: -3px;
}

.app {
  background: #fff;
}

.tbd {
  font-weight: 600;
}

.roundstatus {
  margin-left: 15px;
  font-size: 14px;
  padding: 5px 0px 4px 11px;
  background: red;
  border-radius: 12px;
  /* position: absolute; */
  color: #fff;
  top: -2px;
  width: 76px;
  right: -80px;
}

.withouttab {
  text-align: center;
}

.detailslider .swiper-btn-next,
.detailslider .swiper-btn-prev {
  left: 0;
}

.static-slider {
  width: 100%;
  border-radius: 10px;
}

.buttnformobile button.incompleteteam {
  border: none;
  margin-top: -4px;
  background: #ed3836;
}

.buttnformobile button.completeteam {
  border: none;
  margin-top: 6px;
  background: #ed3836;
}

.arrowhidden {
  display: none !important;
}

.priceimg {
  width: 30%;
}

.timmerdisplaydesktop {
  position: absolute;
  right: 115px;
}

.timmerdisplaydesktop .stopwatchText {
  margin-top: 9px;
  color: #fff;
  font-family: Inter;
  width: 30%;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-right: 12px;
}

.timmerdisplaydesktop .d-flex p {
  color: #fff !important;
  text-decoration: auto;
  margin-top: 0px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 12px;
  text-transform: capitalize;
  text-align: center;
  margin: auto;
}

.timmerdisplaydesktop .d-flex p.timeandsec {
  font-size: 18px;
  color: #000 !important;
  font-weight: 800;
  border-radius: 12px;
  line-height: 17px;
  text-decoration: auto !important;
  width: 45px;
  height: 45px;
  background: #fff;
  margin: 0;
  padding-top: 15px;
}

.timmerdisplaydesktop .text-center {
  margin-right: 8px;
  text-align: center !important;
  margin-left: 8px;
}

.detailBanner {
  border: 1px solid #cccccc5c;
  border-radius: 10px;
}

.alreadyplayedquiz {
  float: right;
  position: absolute;
  right: 0;
}

.downloadpresub {
  /* margin-top: -15px; */
}

.dynamic-submission-user-modal .modal-content {
  border-radius: 10px !important;
}

.dynamic-submission-form {
  overflow: hidden;
  padding: 20px;

  .dynamic-submission-form-content {
    max-height: 50vh;
    overflow-y: auto;
    padding-right: 10px;
    box-sizing: content-box;
    margin-bottom: 10px;

    li {
      list-style: initial !important;
    }

    .uploading-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 50vh;

      .spinner-border {
        margin-top: 10px;
      }

      .uploading-count {
        margin-top: 10px;
        color: #6c757d;
        font-size: 18px;
        font-weight: 600;
      }

      .uploading-info {
        background-color: #f0ebe6;
        padding: 10px;
        border-radius: 8px;
        margin-top: 10px;
        color: #0a0a0a !important;
        font-size: 14px;
        font-weight: 500;
        text-align: justify;
      }
    }
  }

  .uploaded-file-container {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px;
    background-color: #f8f9fa;
    /* Light background for better visibility */
    border: 1px solid #ddd;
    border-radius: 4px;
    justify-content: space-between !important;

    .action-buttons {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: space-between !important;

      svg {
        cursor: pointer;
        height: 15px;
      }

      button {
        height: max-content;
        padding: 3px 7px;
        background-color: #dc3545;
        color: #fff !important;
        border: #dc3545;
        margin-left: 5px;
      }
    }
  }

  @media screen and (max-width: 1240px) {
    .uploaded-file-container {
      flex-direction: column;
    }
  }

  .file-name {
    color: #007bff;
    /* Bootstrap primary color for links */
    font-weight: 500;
    text-decoration: none !important;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
  }

  .file-name:hover {
    color: #0056b3;
    /* Darker blue on hover */
  }

  .btn-delete {
    margin-left: auto;
    /* Push the button to the right */
    padding: 4px 8px;
    font-size: 14px;
    border-radius: 4px;
    background-color: #dc3545;
    color: #fff;
  }

  .btn-delete:hover {
    background-color: #c82333;
    border-color: #bd2130;
  }

  .dynamic-submission-form-close {
    padding: 7px 12px;
    margin-right: 15px;
    font-size: 14px;
    border-radius: 3px;
    border: 1px solid #dc3545;
    background-color: #fff;
    color: #dc3545;
  }

  .dynamic-submission-form-close:not(:disabled):hover {
    background-color: #f7d2d1;
    color: #bc0404;
  }
}

/* Style the scrollbar for WebKit browsers */
.dynamic-submission-form-content::-webkit-scrollbar {
  width: 10px;
  /* Set the width of the scrollbar */
}

.dynamic-submission-form-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Color of the track */
  border-radius: 10px;
  /* Optional: round edges */
}

.dynamic-submission-form-content::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color of the scrollbar thumb */
  border-radius: 10px;
  /* Optional: round edges */
  border: 2px solid #f1f1f1;
  /* Optional: padding around thumb */
}

.dynamic-submission-form-content::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Change color on hover */
}

/* Style for Firefox */
.dynamic-submission-form-content {
  scrollbar-color: #888 #f1f1f1;
  /* thumb color, track color */
  scrollbar-width: thin;
  /* Choose between auto, thin, and none */
}